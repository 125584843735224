<template>
    <slot />
</template>

<script setup>
    import { useEventBus } from '@vueuse/core';
    import {
        EquirectangularReflectionMapping,
        FrontSide,
        LinearFilter,
        MeshPhysicalMaterial,
        TextureLoader,
    } from 'three';
    import { computed, inject, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue';

    import { lerp } from '@resn/gozer-math';
    import { usePane } from '@resn/gozer-vue';

    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';
    import { useGlobalAssets } from '~/providers/GlobalAssets';
    import { applyCardTextureRotation } from '~/utils/card-texture-utils';

    // Define props with default values
    const props = defineProps({
        active: { default: false },
        object: { default: null },
        imgUrl: { default: null },
        side: { default: FrontSide },
        flip: { default: false },
        layer: { default: 0 },
        name: { default: 'Card Inner' },
        grader: { defaut: 'psa' },
        // new feature: Specifies the orientation of the card texture
        direction: { default: undefined },
    });

    // Reactive references for material properties
    const visible = ref(true);
    const { depthOffset, revealPr, inProgress } = inject('card');
    const showMaterial = computed(() => revealPr.value > 0.0);

    const metalness = ref(0.8);
    const roughness = ref(1);
    const iridescence = ref(0.3);
    const iridescenceIOR = ref(1.3);
    const color = ref('white');
    const envMapIntensity = ref(3);
    const alphaTest = ref(0.5);

    const bus = useEventBus('card-inner');

    // Create and configure the material
    const material = new MeshPhysicalMaterial({ name: 'Card Inner', transparent: true });

    // Configure the object
    let objectMain;

    // Set up global assets (environment map and alpha map)
    const loader = useGlobalAssets(({ data }) => {
        data.envMap.mapping = EquirectangularReflectionMapping;

        material.envMap = data.envMap;

        const cardId = `card-mask-${props.grader}`;
        material.alphaMap = data[cardId];
    });

    const setObject = () => {
        if (objectMain) objectMain.parent.remove(objectMain);

        objectMain = props.object;
        objectMain.rotation.y = props.flip ? Math.PI : 0;
        objectMain.material = material;

        if (loader.assets) {
            const data = loader.assets;
            const cardId = `card-mask-${props.grader}`;
            material.alphaMap = data[cardId];
        }

        return objectMain;
    };

    objectMain = setObject();

    // Custom shader modification
    const uniforms = { progress: { value: 0 } };
    material.onBeforeCompile = (shader) => {
        shader.uniforms.progress = uniforms.progress;

        // Add progress uniform to fragment shader
        shader.fragmentShader = shader.fragmentShader.replace(
            `#include <common>`,
            `
            #include <common>
            uniform float progress;
            `
        );

        // Modify fragment color based on progress
        shader.fragmentShader = shader.fragmentShader.replace(
            `#include <dithering_fragment>`,
            `
            gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(0.0), progress);
            `
        );
    };

    // Function to set texture
    const setTexture = (url) => {
        if (!url) {
            console.warn('No image URL provided for texture');
            return;
        }

        loadBitmapTexture(url, {
            flipY: true,
            minFilter: LinearFilter,
        }).then((texture) => {
            material.map?.dispose();
            applyCardTextureRotation(props.name, props.direction, texture);
            material.map = texture;
            material.roughnessMap = texture;
            material.needsUpdate = true;

            bus.emit('texture-loaded');
        });
    };

    // Function to set material visibility
    const setMaterial = (val) => {
        objectMain.visible = val;
    };

    // Options for debug UI
    const options = { min: 0, max: 1, step: 0.01 };

    // Update material properties
    watchEffect(() => {
        material.metalness = metalness.value;
        material.roughness = roughness.value;
        material.iridescence = iridescence.value;
        material.iridescenceIOR = iridescenceIOR.value;
        material.envMapIntensity = envMapIntensity.value;
        material.alphaTest = alphaTest.value;
        // material.opacity = showMaterial.value ? inProgress.value : 0;

        // uniforms.progress.value = depthOffset.value;
        uniforms.progress.value = lerp(inProgress.value, 1, depthOffset.value);
        // if (props.active) console.log('🚀 ~ watchEffect ~ inProgress.value:', inProgress.value);
        // uniforms.progress.value = (1 - inProgress.value) * depthOffset.value;
        objectMain.visible = visible.value;
    });

    // Watch for changes in showMaterial and imgUrl
    watch(showMaterial, setMaterial);
    watch(() => props.imgUrl, setTexture);
    watch(() => props.object, setObject);

    // Handle object position
    const pos = ref({ x: 0, y: 0, z: 0 });
    watchEffect(() => {
        objectMain.position.set(pos.value.x, pos.value.y, pos.value.z);
    });

    // Lifecycle hooks
    onMounted(() => {
        setMaterial(showMaterial.value);
        if (props.imgUrl) {
            setTexture(props.imgUrl);
        }
    });

    onBeforeUnmount(() => {
        material.dispose();
    });

    // Debug UI setup
    usePane(
        [
            { name: 'visible', value: visible },
            { name: 'pos', value: pos },
            { name: 'metalness', value: metalness, options },
            { name: 'roughness', value: roughness, options },
            { name: 'iridescence', value: iridescence, options },
            {
                name: 'iridescenceIOR',
                value: iridescenceIOR,
                options: { min: 1, max: 2, step: 0.01 },
            },
            { name: 'color', value: color },
            { name: 'alphaTest', value: alphaTest, options },
            {
                name: 'envMapIntensity',
                value: envMapIntensity,
                options: { min: 0, max: 10, step: 0.1 },
            },
        ],
        { title: props.name, expanded: false }
    );
</script>

<style lang="scss" scoped></style>

import { ShaderMaterial } from 'three';

import { materialEasyUniforms, simpleFs, simpleVs } from '@resn/gozer-three';

import {
    shaderChunks as ShaderChunksMatcap,
    uniforms as uniformsMatcap,
} from '../shaders/ShaderChunks/matcap';
import { wipeChunks } from '../shaders/ShaderChunks/wipe-reveal';
import { neutralTonemappingChunks as glslNeutralTonemapping } from '@/components/gl/shaders/ShaderChunks/neutral-tonemapping.js';
import glslOverlay from '@/libs/lygia/color/blend/overlay.glsl';
import glslCircle from '@/libs/lygia/draw/circle.glsl';
import glslIridescence from '@/libs/lygia/lighting/iridescence.glsl';
import glslCircleSdf from '@/libs/lygia/sdf/circleSDF.glsl';
import glslScale from '@/libs/lygia/space/scale.glsl';
import { createNoiseFromTexture2 } from '~/libs/three/noise-texture';

class SlabMaterial2 extends ShaderMaterial {
    options = {
        name: 'SlabMaterial2',
        transparent: true,
    };

    constructor({ uniforms = {}, vs = simpleVs, fs = simpleFs, options = {} } = {}) {
        Object.keys(uniforms).forEach((key) => {
            uniforms[key] = { value: uniforms[key] };
        });

        super({
            uniforms: { ...uniformsMatcap(), ...wipeChunks.uniforms(), ...uniforms },
            vertexShader: vertexShader, // vs
            fragmentShader: fragmentShader, // fs
        });

        this.defines = {
            USE_UV: true,
        };

        this.options = { ...this.options, ...options };

        this.type = this.options.name;
        this.extensions.derivatives = true;

        Object.keys(this.options).forEach((key) => (this[key] = this.options[key]));
        materialEasyUniforms(this);
    }
}

export default SlabMaterial2;

const vertexShader = /* glsl */ `
    // Vertex Shader
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    varying vec2 vUv;
    varying vec2 vUv1;
    varying vec3 vEyeVector;
    varying vec3 vWorldNormal;
    varying vec4 vClipPosition;
    attribute vec2 uv1;

    void main() {
        // Transform the normal to world space
        vNormal = normalize(normalMatrix * normal);
        vUv = uv;
        vUv1 = uv1;

        // Calculate the view position in world space
        vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
        vViewPosition = viewPosition.xyz;


        vec4 worldPosition = modelMatrix * vec4( position, 1.0);
        vEyeVector = normalize(worldPosition.xyz - cameraPosition);
        vWorldNormal = normalize( modelViewMatrix * vec4(normal, 0.0)).xyz;

        gl_Position = projectionMatrix * viewPosition;
        
        vClipPosition = gl_Position;
    }
`;

const fragmentShader = /* glsl */ `
    // Fragment Shader
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    varying vec2 vUv;
    varying vec2 vUv1;
    varying vec3 vEyeVector;
    varying vec3 vWorldNormal;
    varying vec4 vClipPosition;
    
    uniform sampler2D uCardTexture;
    uniform sampler2D uAlphaMask;
    uniform sampler2D uOutline;
    uniform sampler2D uNoise;
    uniform sampler2D uWhiteNoise;
    uniform sampler2D uEnvMap;
    uniform sampler2D uPreRevealSticker;
    uniform float uStickerAspect;
    uniform sampler2D tMatcap2;
    uniform float uTime;
    uniform float uAlpha;
    uniform float uInProgress;
    uniform float uIor;
    uniform float uDispersionAmount;
    uniform float uMatcapRotation;
    uniform vec3 uPreRevealColor1;
    uniform vec3 uPreRevealColor2;
    uniform float uDepthOffset;
    uniform float uHeartbeatPr;
    
    uniform float uLightsDownPr;
    uniform float uLightsDownRevealPr;
    
    #define PI 3.1415926535897932384626433832795
    #define NUM_COLORS 3
    uniform vec3 uColorPalette[NUM_COLORS];

    uniform vec2 uRes;

    ${glslIridescence}
    ${glslCircle}
    ${glslCircleSdf}
    ${glslOverlay}
    ${glslScale}

    ${ShaderChunksMatcap.uniforms}
    ${ShaderChunksMatcap.funcs}
    ${wipeChunks.funcs}
    ${glslNeutralTonemapping.funcsVariant}

    float createCircle(vec2 uv, float angle, float dist, float radius, float thickness) {
        uv -= 0.5;
        uv.y *= 1.8;
        uv.x += sin(angle) * dist;
        uv.y += cos(angle) * dist;
        uv += 0.5;
        float circleShape = fill(circleSDF(uv), radius, thickness);
        
        return circleShape;
    }

    void main() {
        // Colors
        vec3 color1 = uColorPalette[0];
        vec3 color2 = uColorPalette[1];
        vec3 color3 = uColorPalette[2];
        vec3 black = vec3(0.0);

        // Screen UV
        vec2 screenUv = (vClipPosition.xy / vClipPosition.w + 1.0) / 2.0;

        vec3 normal = normalize(vNormal);
        vec3 viewDir = normalize(-vViewPosition); // The eye ray is from the fragment to the camera
        float dotProduct = dot(normal, viewDir);
        vec3 refracted = refract(vEyeVector, vWorldNormal, 1.0/uIor);
        float noise = texture2D(uWhiteNoise, vUv1 * 3.0).r;
        vec3 irridescentColor = iridescence(dotProduct, 0.9);
        vec3 reflectedDir = reflect(viewDir, normal);


        // The shift amount increases as the angle decreases
        // float dispersion = (uRes.x / 1280.0) * uDispersionAmount; 
        float rgbShiftStr = uDispersionAmount * (1.0 - dotProduct);
        rgbShiftStr = min(rgbShiftStr, 0.002);

        // EnvMap
        vec4 envColor = texture2D(uEnvMap, reflectedDir.xy);
        // vec4 envColor = textureCubeUV(uEnvMap, reflectedDir, 1.0);


        // Matcap version 1
        vec3 matcapNormal = vNormal + noise * 0.08;
        vec3 matcapColor = matcap(tMatcap, matcapNormal, vViewPosition, false, 1.0, 1.0, 1.0, toRadians(uMatcapRotation));

        // Wipe
        vec4 wipeOutput = wipe(vUv, uWipeAngle, uRevealPr, uPreRevealColor2, uPreRevealColor1, uPreRevealColor2, uNoise, uTime);
            
        
        // Card Texture
        vec4 alphaTex = texture2D(uAlphaMask, vUv + refracted.xy * 0.1);
        float cardMask = 1.0 - alphaTex.r;
        vec4 cardTex = texture2D(uCardTexture, screenUv + refracted.xy * 0.01);

        // RGB Shift
        // vec4 cardTexRgbShift;
        // vec2 rgbOffset = vec2(rgbShiftStr, 0.0);

        // cardTexRgbShift.r = texture2D(uCardTexture, screenUv + rgbOffset).r;
        // cardTexRgbShift.g = texture2D(uCardTexture, screenUv).g;
        // cardTexRgbShift.b = texture2D(uCardTexture, screenUv - rgbOffset).b;
        // cardTexRgbShift.a = texture2D(uCardTexture, screenUv).a;
        
        // Lights Down
        float lightDownRevealWipe = 1.0 - length((vUv.y - 0.5 + mix(-1.0, 1.0, uLightsDownRevealPr)) * 2.0);
        vec3 lightsDownColor = vec3(0.0);
        // float lightDownRevealWipe = dotProduct;

        // Pre Reveal
        vec2 circleUv = vUv - 0.5;
        circleUv.y *= 1.8;

        vec4 outlineTex = texture2D(uOutline, vUv);
        
        vec2 cardAspect = vec2(1.0, 1.76);
        vec2 stickerAspect = vec2(1.0, uStickerAspect);
        vec2 uvSticker = scale(vUv, cardAspect * stickerAspect * 3.0);
        // vec2 uvSticker = vUv * vec2(1.0, 1.76);
        vec4 preRevealSticker = texture2D(uPreRevealSticker, uvSticker);

        float dist = mix(0.8, 0.7, uHeartbeatPr);
        float radius = mix(0.7, 1.5, uHeartbeatPr);
        float thickness = 0.5;
        float circle1 = createCircle(vUv, uTime, dist, radius, thickness);
        float circle2 = createCircle(vUv, uTime + PI, dist, radius, thickness);
        float circle3 = createCircle(vUv, uTime * 1.2, dist, radius, thickness);
        float circle = circle1 + circle2 + circle3 * 0.2;
        vec3 circleColor = circle1 * 3.0 * uPreRevealColor1 + circle2 * 3.0 * uPreRevealColor2 + circle3 * uPreRevealColor1 * 0.2;
        vec3 preRevealColor = outlineTex.rgb * circleColor;
        preRevealColor = mix(preRevealColor, preRevealSticker.rgb, preRevealSticker.a);
        preRevealColor += circleColor * mix(0.002, 0.004, uHeartbeatPr);

        preRevealColor = mix(preRevealColor, lightsDownColor, uLightsDownPr);


        // Pre Reveal Output
        vec4 preRevealOutput = vec4(preRevealColor, uAlpha);
        // vec4 preRevealOutput = vec4(vec3(circle), 1.0);
        // vec4 preRevealOutput = vec4(vec3(matcapColor), 1.0);

        // Main Output
        // mainBaseColor = vec3(1.0);
        vec3 mainBaseColor = blendScreen(envColor.rgb, color2);
        mainBaseColor = mix(vec3(1.0), mainBaseColor, 0.75);

        float pmndrsMin = 0.25;
        // float pmndrsMin = 0.3;
        float threeMin = 0.25;
        float mainAlpha = mix(pmndrsMin * cardMask, 1.0, matcapColor.r) - noise * 0.1;
        vec4 mainMatcap = vec4(mainBaseColor, mainAlpha);
        vec4 mainOutput = mainMatcap;
        // mainOutput.rgb = mix(mainMatcap.rgb, envColor.rgb, 0.5);
        // mainOutput.rgb = envColor.rgb;
        // mainOutput = mix(mainMatcap, envColor, 1.0);
        // mainOutput.rgb = mainMatcap.rgb * envColor.rgb;

        // Final Blend
        vec4 finalColor = mix(preRevealOutput, mainOutput, wipeOutput.a);
        finalColor.rgb = blendScreen(finalColor.rgb, wipeOutput.rgb);
        finalColor.rgb = mix(black, finalColor.rgb, uInProgress);
        gl_FragColor = finalColor;

        // Fog
        float fog = mix(1.0, 0.0, uDepthOffset);
        
        gl_FragColor.rgb = mix(black, gl_FragColor.rgb, fog);
        gl_FragColor.a *= uAlpha;
        

        
        // gl_FragColor.rgb = NeutralToneMapping0(gl_FragColor.rgb);

        // #include <tonemapping_fragment>
        // #include <colorspace_fragment>
        // gl_FragColor = preRevealSticker;
        // gl_FragColor = vec4(vec3(color2), 1.0);
        // gl_FragColor = vec4(vec3(vUv1, 0.0), 1.0);
        // gl_FragColor = texture2D(uOutline, vUv);
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        // gl_FragColor = vec4(1.0, 1.0, 1.0, 0.2);
    }
`;

/**
 * Maps card directions to their opposite sides for proper orientation
 * Example: if the card opens to the right, the inner texture needs to be flipped to the left
 */
const directionMap = {
    right: 'left',
    left: 'right',
    top: 'top',
    bottom: 'bottom',
};

/**
 * Applies the appropriate rotation to a texture based on card name and direction
 * @param {string} cardName - Name of the card component ('Card Front' or 'Card Back')
 * @param {string} direction - Direction of card opening ('top', 'right', 'bottom', 'left')
 * @param {THREE.Texture} texture - The texture to apply rotation to
 */
export const applyCardTextureRotation = (cardName, direction = 'top', texture) => {
    if (!texture || !texture.image) return;
    console.log(direction, cardName);
    // Set rotation center to middle of texture for proper rotation handling
    texture.center.set(0.5, 0.5);

    // Check if image dimensions match expected orientation
    const isLandscapeDimension = texture.image.width > texture.image.height;

    // Safety check: Handle unexpected landscape images for vertical cards
    // Rotate them appropriately based on which side they're on (front/back)
    if (isLandscapeDimension && ['top', 'bottom'].includes(direction)) {
        const sign = cardName === 'Card Front' ? -1 : 1;
        texture.rotation = (Math.PI / 2) * sign;
        return;
    }

    // Get the appropriate direction based on whether it's front or back
    // const dir = cardName === 'Card Front' ? direction : directionMap[direction];
    const dir = cardName === 'Card Front' ? direction : directionMap[direction];

    // Apply rotation based on card opening direction
    // This ensures the texture is oriented correctly relative to how the card opens
    switch (dir) {
        case 'top':
            break;
        case 'right':
            texture.rotation = Math.PI / 2; // 90 degrees clockwise
            break;
        case 'bottom':
            texture.rotation = Math.PI; // 180 degrees
            break;
        case 'left':
            texture.rotation = -Math.PI / 2; // 90 degrees counterclockwise
            break;
    }
};
